import Vue from 'vue'

const moment = require('moment-timezone')
const momentDurationFormatSetup = require('moment-duration-format')
momentDurationFormatSetup(moment)
require('moment/locale/ru')

Vue.use(require('vue-moment'), { moment })

Vue.filter('formatDate', function (value) {
  if (value) {
    return Vue.$moment(value).format('DD.MM.YYYY')
  }
})
