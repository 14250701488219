<template>
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="green"
        :content="ribbonEvent.count"
        :value="ribbonEvent.count"
        offset-y="20"
        offset-x="20"
      >
        <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-bell</v-icon></v-btn>
      </v-badge>
    </template>
    <v-card width="500px">
      <v-card-title class="text-subtitle-2">Лента изменений</v-card-title>
      <v-divider></v-divider>
      <v-card v-if="ribbonEvent.items.length == 0">
        <v-card-text>Событий нет</v-card-text>
      </v-card>
      <v-card
        v-else
        flat
        height="560"
        class="overflow-y-auto"
      >
        <v-card flat>
          <v-card class="my-1" v-for="(item, idx) in ribbonEvent.items" :key="idx">
            <div class="d-flex justify-start">
              <v-icon color="green" v-if="item.mark">mdi-circle-medium</v-icon>
              <router-link :to="item.url">
                <v-card-title class="text-subtitle-2 pb-0 pt-2">{{ item.code }} (<span class="text-caption">{{ item.history.subtitle | moment('timezone', 'Asia/Yekaterinburg', 'LLL') }}</span>)</v-card-title>
              </router-link>
            </div>
            <v-list dense class="py-0">
              <v-list-item
                v-for="(text, i) in item.history.text"
                :key="i"
              >
                <v-list-item-content class="text-body-2 py-0">{{ text }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-card>
      </v-card>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'RibbonEvent',
  data: () => ({
  }),
  created () {
  },
  computed: {
    ribbonEvent: function () {
      return this.$store.state.ribbonEvent
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
