<template>
  <v-app>
    <v-main v-if="!$auth.ready()">
      <v-container fluid>Загрузка...</v-container>
    </v-main>
    <template v-else>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-app-bar v-if="$auth.user()" app color="#e31e25" dark clipped-left>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="px-6" v-if="breakpointLgXl">Task Center</v-toolbar-title>
        <v-progress-linear :active="Boolean($store.state.loading)" color="primary" height="5" indeterminate absolute bottom></v-progress-linear>
        <v-divider vertical class="mr-4" dark />

        <template v-for="(item, key) in buttons">
          <v-btn icon :key="key" v-tooltip.bottom="item.name" v-if="hasButton(key)" @click="$eventHub.$emit(key)">
            <v-icon v-text="item.icon" />
          </v-btn>
        </template>

        <v-spacer></v-spacer>
        <div>
          <ribbon-event></ribbon-event>
          <user-menu></user-menu>
        </div>
      </v-app-bar>
      <router-view></router-view>
      <v-footer
          v-if="$auth.user()"
          color="#e31e25"
          dark
          app
          style="z-index: 1100"
      >
        <span class="white--text">&copy; 2020, RED-Group</span>
        <v-spacer v-if="breakpointLgXl" class="hidden-sm-and-down" />
        <div v-if="breakpointLgXl" class="body-1 font-weight-light pt-6 pt-md-0 text-left white--text">
          Техническая поддержка: <span class="font-weight-bold">8 (343) 351-70-20 вн. 106</span>
          <a class="font-weight-medium" style="color: darkblue; margin-left: 15px;" href="mailto:aleksandr.tretyakov@red-group.ru">E-Mail</a>
        </div>
      </v-footer>
    </template>
    <FlashMessage style="z-index: 1200" position="right bottom" clickable="true"/>
  </v-app>
</template>

<script>
import { mixin as VueTimers } from 'vue-timers'
import RibbonEvent from './components/RibbonEvent'
import UserMenu from './components/UserMenu'
export default {
  name: 'App',
  components: { UserMenu, RibbonEvent },
  mixins: [VueTimers],
  timers: {
    checkVersion: { time: 60000, repeat: true, autostart: true, immediate: true }
  },
  data: () => ({
    buttons: {
      back: {
        name: 'Назад',
        icon: 'mdi-keyboard-backspace'
      },
      refresh: {
        name: 'Обновить',
        icon: 'mdi-refresh'
      },
      add: {
        name: 'Добавить',
        icon: 'mdi-plus'
      },
      edit: {
        name: 'Редактировать',
        icon: 'mdi-square-edit-outline'
      }
    }
  }),
  computed: {
    overlay: function () {
      return this.$store.state.overlay
    },
    breakpointLgXl () {
      return ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name) !== -1
    },
    drawer: {
      get: function () {
        return this.$store.state.drawer
      },
      set: function (value) {
        this.$store.commit('SET_DRAWER', value)
      }
    }
  },
  methods: {
    hasButton (button) {
      const buttons = this.$route.meta.buttons || {}
      // eslint-disable-next-line no-prototype-builtins
      if (!buttons.hasOwnProperty(button)) return false

      const rule = buttons[button]
      if (typeof rule === 'boolean') return rule

      return this.$auth.check(rule)
    },
    checkVersion () {
      this.$store.dispatch('CHECK_VERSION')
    }
  }
}
</script>

<style lang="scss">
  .tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
      background: #616161;
      color: white;
      border-radius: 6px;
      padding: 5px 10px 4px;
      font-family: "Roboto", sans-serif;
      opacity: 0.85;
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: #616161;
      z-index: 1;
    }

    &[x-placement^="top"] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="bottom"] {
      margin-top: -5px;

      .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="right"] {
      margin-left: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^="left"] {
      margin-right: 5px;

      .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }
  }
</style>
