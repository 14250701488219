<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="#e31e25" dark flat>
            <v-toolbar-title>Восстановление пароля</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              v-model="validatePassword"
              ref="resetPasswordForm"
              lazy-validation
              @submit.prevent="recovery"
            >
              <v-text-field
                v-model="password1"
                label="Пароль"
                prepend-icon="mdi-lock"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                :rules="[rules.required, rules.min]"
                @click:append="show1 = !show1"
              ></v-text-field>
              <v-text-field
                v-model="password2"
                label="Подтверждение пароля"
                prepend-icon="mdi-lock"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                :rules="[rules.required, rules.min, passMatch]"
                @click:append="show2 = !show2"
                @keyup.enter="recovery"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!validatePassword" @click="recovery">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    password1: '',
    password2: '',
    token: null,
    show1: false,
    show2: false,
    validatePassword: false,
    rules: {
      required: v => !!v || 'Обязательное поле',
      min: v => v.length >= 8 || 'Длина не менее 8 символов'
    }
  }),
  created () {
    this.token = this.$route.params.token
  },
  mounted () {
  },
  computed: {
    passMatch: function () {
      return this.password2 === this.password1 || 'Пароли не совпадают'
    }
  },
  methods: {
    recovery () {
      if (!this.$refs.resetPasswordForm.validate()) {
        return
      }
      const payload = {
        password1: this.password1,
        password2: this.password2,
        token: this.token
      }
      this.$store.dispatch('auth/POST_RESET_PASSWORD', payload)
        .then(response => {
          this.flashMessage.success({ title: 'Сброс пароля', message: 'Новый пароль успешно установлен' })
          this.$router.push({ path: '/login' })
        })
    }
  }
}
</script>

<style scoped>

</style>
