<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="#e31e25" dark flat>
            <v-toolbar-title>{{ recovery ? 'Восстановление пароля' : 'Авторизация' }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              v-model="loginValidate"
              ref="loginForm"
              lazy-validation
              @submit.prevent="login"
            >
              <v-text-field
                v-model="user.email"
                label="Email"
                prepend-icon="mdi-account"
                :rules="rules.emailRules"
                type="text"
              ></v-text-field>
              <v-text-field
                v-if="!recovery"
                v-model="user.password"
                label="Пароль"
                prepend-icon="mdi-lock"
                type="password"
                :rules="[rules.required, rules.min]"
                @keyup.enter="login"
              ></v-text-field>
            </v-form>
            <v-checkbox v-model="recovery" dense label="Восстановление пароля"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="recovery" color="primary" @click="recoveryPass">Восстановить</v-btn>
            <v-btn v-else color="primary" :disabled="!loginValidate" @click="login">Вход</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    user: {
      email: '',
      password: ''
    },
    recovery: false,
    loginValidate: true,
    rules: {
      emailRules: [
        v => !!v || 'Поле E-mail обязательное',
        v => /.+@.+\..+/.test(v) || 'E-mail должен быть корректным'
      ],
      required: v => !!v || 'Обязательное поле',
      min: v => v.length >= 8 || 'Длина не менее 8 символов'
    }
  }),
  methods: {
    login () {
      if (!this.$refs.loginForm.validate()) {
        return
      }
      this.$auth.login({
        data: this.user,
        rememberMe: true,
        redirect: '/'
      }).catch(res => {
        this.flashMessage.error({ title: 'Ошибка входа', message: res.response.data.message })
      })
    },
    recoveryPass () {
      // const this_ = this
      const payload = {
        email: this.user.email
      }
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('auth/POST_REQUEST_RESET_PASSWORD', payload)
        .then(response => {
          this.recovery = false
          this.user.email = ''
          this.$refs.loginForm.resetValidation()
          this.flashMessage.success({ title: 'Сброс пароля', message: 'На почту отправлено письмо с инструкцией по восстановлению пароля.' })
        })
        .finally(() => { this.$store.commit('OVERLAY_OFF') })
    }
  }
}
</script>

<style scoped>

</style>
