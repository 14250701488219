import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import EventHub from './plugins/eventhub'
import FlashMessage from '@smartweb/vue-flash-message'
import './plugins/moment'
import VTooltip from 'v-tooltip'
import { VueMaskFilter } from 'v-mask'
import VueTheMask from 'vue-the-mask'
import MultiFiltersPlugin from './plugins/MultiFilters'
import './assets/styles.less'

import http from './http'
import store from './store'
import router from './router'
import config from './config'

import BaseCard from './components/base/BaseCard'
import BaseDialog from './components/base/BaseDialog'
import DatePicker from './components/DatePicker'

Vue.config.productionTip = false

Vue.use(VTooltip)
Vue.use(FlashMessage,
  {
    time: 10000,
    strategy: 'multiple'
  })
Vue.use(MultiFiltersPlugin)
Vue.use(VueTheMask)

Vue.component(BaseCard.name, BaseCard)
Vue.component(BaseDialog.name, BaseDialog)
Vue.component(DatePicker.name, DatePicker)

// Vue.directive('mask', VueMaskDirective)
Vue.filter('VMask', VueMaskFilter)

Vue.prototype.$eventHub = EventHub

new Vue({
  http: http,
  router: router,
  store: store,
  config: config,
  vuetify: vuetify,
  render: h => h(App)
}).$mount('#app')
