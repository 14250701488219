import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

import project from './project.modules'
import task from './task.modules'
import ticket from './ticket.modules'
import dashboard from './dashboard.modules'
import attachment from './attachment.modules'
import user from './user.modules'
import auth from './auth.modules'
import bpm from './bpm.modules'
import comments from './comments.modules'
import org from './reference/org.modules'
import facility from './reference/facility.modules'
import partners from './reference/partners.modules'
import reference from './reference/reference.modules'
import hr from './hr.modules'
import settings from './settings.modules'
import debit from './debit.modules'
import members from './reference/members.modules'
import phoneBook from './phoneBook.modules'
import rn from './rn.modules'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: 0,
    versionBase: JSON.parse(unescape(process.env.PACKAGE_JSON)).version,
    versionHash: window.APPHASH,
    overlay: false,
    drawer: true,
    glob_state: localStorage.getItem('glob_state') || 0,
    ribbonEvent: {
      count: 0,
      items: []
    }
  },
  getters: {
    get_glob_state: state => state.glob_state,
    getRibbonEvent: state => state.ribbonEvent
  },
  mutations: {
    OVERLAY_ON (state) {
      state.overlay = true
    },
    OVERLAY_OFF (state) {
      state.overlay = false
    },
    GLOBAL_LOADING (state, v) {
      v ? state.loading++ : state.loading--
    },
    SET_DRAWER (state, v) {
      state.drawer = v
    },
    SET_GLOB_STATE (state, value) {
      state.glob_state = value
      localStorage.setItem('glob_state', value)
    }
  },
  actions: {
    _get ({ dispatch, commit }, { path, params }) {
      commit('GLOBAL_LOADING', true)
      return Vue.axios.get(path, params)
        .then(response => dispatch('getData', response))
        .catch(error => dispatch('showError', error))
        .finally(() => { commit('GLOBAL_LOADING', false) })
    },
    _post ({ dispatch, commit }, { path, payload, config }) {
      commit('GLOBAL_LOADING', true)
      return Vue.axios.post(path, payload, config)
        .then(response => dispatch('getData', response))
        .catch(error => dispatch('showError', error))
        .finally(() => { commit('GLOBAL_LOADING', false) })
    },
    _put ({ dispatch, commit }, { path, payload }) {
      commit('GLOBAL_LOADING', true)
      return Vue.axios.put(path, payload)
        .then(response => dispatch('getData', response))
        .catch(error => dispatch('showError', error))
        .finally(() => { commit('GLOBAL_LOADING', false) })
    },
    _delete ({ dispatch, commit }, { path, params }) {
      commit('GLOBAL_LOADING', true)
      return Vue.axios.delete(path, { params })
        .then(response => dispatch('getData', response))
        .catch(error => dispatch('showError', error))
        .finally(() => { commit('GLOBAL_LOADING', false) })
    },
    showError (_, error) {
      console.log('showError', error)
      // console.log('showError', error.status)
      let title = 'Ошибка'
      const message = error.response?.data?.message || ''
      const msg = error.response?.data?.error || message
      switch (error.response.status) {
        case 500:
          title = 'Ошибка сервера'
          this._vm.flashMessage.error({ title, message })
          break
        case 400:
          title = 'Ошибка в запросе'
          break
        case 401:
          title = 'Требуется авторизация'
          break
        case 403:
          title = 'Авторизация'
          this._vm.flashMessage.error({ title, message: msg })
          return
        case 404:
          title = 'Объект не найден'
          router.push({ name: '404 Error' })
          break
        case 413:
          title = 'Ошибка данных'
          error.response.data.message = 'Превышен размер загружаемых данных'
          break
      }
      error.response.data.title = title
      // this._vm.flashMessage.error({ title, message })
      throw error
    },
    getData (_, response) {
      let errorMessage
      if (response.headers['content-disposition'] !== undefined &&
        response.headers['content-disposition'].indexOf('attachment') !== -1) {
        return response
      }
      if (response.data == null) {
        errorMessage = { response: { data: { message: 'Сервер не передал данные' } }, status: 500 }
        throw errorMessage
      }
      if (!Object.prototype.hasOwnProperty.call(response.data, 'status')) {
        errorMessage = { response: { data: { message: 'Неопознанный формат данных' } }, status: 500 }
        throw errorMessage
      }
      if (response.data.status !== 'success') {
        switch (response.data.status) {
          case 'noNewData':
            console.log('noNewData')
            errorMessage = { response: { data: { message: response.data.message || 'Нет обновленных данных' } }, status: 200 }
            break
          default:
            errorMessage = { response: { data: { message: response.data.message || 'Неизвестная ошибка, Код ' + response.data.status } }, status: 500 }
        }
        console.log(errorMessage)
        throw errorMessage
      }
      if (!Object.prototype.hasOwnProperty.call(response.data, 'data')) {
        errorMessage = { response: { data: { message: 'Элемент данных отсутствует' } }, status: 500 }
        throw errorMessage
      }
      if (Object.prototype.hasOwnProperty.call(response.data, 'ribbonEvent')) {
        this.state.ribbonEvent = response.data.ribbonEvent
      }
      return response.data.data
    },
    CHECK_VERSION ({ state }) {
      Vue.axios.get('/build-hash.json?' + Math.random() * 1000000000)
        .then(response => {
          if (state.versionHash !== response.data.hash) {
            this._vm.flashMessage.warning({
              title: 'Новая версия',
              html: '<div style="margin: 20px;"><div>Появилась новая версия интерфейса, перезагрузите страницу в браузере</div><div>Для перезагрузки нажмите <strong>Ctrl+F5</strong></div></div>',
              clickable: false,
              time: 60000
            })
          }
        })
    }
  },
  modules: {
    project,
    task,
    ticket,
    dashboard,
    attachment,
    user,
    auth,
    bpm,
    comments,
    org,
    facility,
    partners,
    reference,
    hr,
    settings,
    members,
    phoneBook,
    rn,
    debit
  }
})
