const state = {
}

const actions = {
  GET_ATTACHMENTS ({ commit, dispatch, state }, { id, type }) {
    return dispatch('_get', { path: `/api/v1/attachment/${type}/${id}/` }, { root: true })
  },
  POST_UPLOAD_FILES ({ commit, dispatch, state }, { id, type, formData }) {
    return dispatch('_post', {
      path: `/api/v1/attachment/${type}/${id}/uploads/`,
      payload: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }, { root: true })
  },
  GET_DOWNLOAD_FILE ({ commit, dispatch, state }, { id, type, filename }) {
    return dispatch('_get', {
      path: `/api/v1/attachment/${type}/${id}/${filename}`,
      params: { responseType: 'blob' }
    }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  actions
}
