<template>
  <v-main>
    <v-navigation-drawer
      v-model="$store.state.drawer"
      app
      clipped
      expand-on-hover
      ref="navigationDrawer"
    >
      <v-card
        class="d-flex flex-column"
        flat
      >
        <task-navigation></task-navigation>
      </v-card>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="$store.state.rn.rightDrawer"
      temporary
      right
      fixed
      :width="$store.state.rn.width"
      stateless
      @transitionend="resetRightDrawer"
    >
      <component :is="rightComponent"></component>
    </v-navigation-drawer>
    <router-view/>
  </v-main>
</template>

<script>
import TaskNavigation from '../components/navigation/TaskNavigation'
// import TicketNavigation from '../components/navigation/TicketNavigation'
export default {
  name: 'Index',
  components: {
    TaskNavigation
  },
  data: () => ({
  }),
  created () {
    this.getData()
  },
  computed: {
    title () {
      return this.$route.meta.title?.replace('{id}', '<span class="secondary--text">' + this.$route.params.id + '</span>')
    },
    toggle: {
      get: function () {
        return parseInt(this.$store.getters.get_glob_state)
      },
      set: function (value) {
        this.$store.commit('SET_GLOB_STATE', value)
      }
    },
    rightComponent () {
      const component = this.$store.state.rn.component
      return () => import(`../components/rightComponent/${component}`)
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('bpm/GET_DEPARTMENT')
    },
    resetRightDrawer () {
      // this.$store.commit('rn/RESET_VIEW')
    }
  }
}
</script>

<style scoped>

</style>
