const state = {
  department: [],
  workflowState: {
    type: '',
    object_id: null,
    workflow: [],
    obj: {
      whom_id: null,
      is_closed: false,
      change_params: [],
      status: ''
    },
    appraisal: [],
    appraisalDialog: false,
    appraisalIndex: -1,
    appraisalAction: false
  },
  timeline: null,
  status: {
    status: '',
    whom: ''
  },
  workflowComment: false,
  workflowAction: -1,
  reason: {
    dialog: false,
    index: -1,
    action: false,
    reason: null,
    text: null,
    other: null
  },
  dateChange: { // Данные для смены даты исполнения (deadline_dt)
    dateChangeDialog: false,
    newDate: '',
    newStart: '',
    newDeadline: '',
    setDate: false
  },
  commentText: '',
  flashMessage: '',
  changeStatus: {} // статус изменений, подсветка, шрифт и т.д., еще не реализован
}

const getters = {
  isDeadlineChange (state) {
    // TODO сделать подсветку изменений
    return !!state.changeStatus
  }
}

const mutations = {
  OPEN_DATE_CHANGE_DIALOG (state) {
    state.dateChange.dateChangeDialog = true
  },
  CLOSE_DATE_CHANGE_DIALOG (state) {
    state.dateChange.dateChangeDialog = false
    state.dateChange.setDate = false
  },
  OPEN_APPRAISAL_DIALOG (state, index) {
    state.workflowState.appraisal[index] = state.workflowState.workflow.data_status[index].appraisal
    state.workflowState.appraisalIndex = index
    state.workflowState.appraisalDialog = true
  },
  CLOSE_APPRAISAL_DIALOG (state) {
    state.workflowState.appraisalDialog = false
  },
  SET_APPRAISAL (state, { name, val, index }) {
    state.workflowState.appraisal.splice(index, 1, { ...state.workflowState.appraisal[index], [name]: val })
  },
  SET_APPRAISAL_ACTION (state, value) {
    state.workflowState.appraisalAction = value
  },
  RESET_APPRAISAL (state) {
    const index = state.workflowState.appraisalIndex
    state.workflowState.appraisal[index] = { ...state.workflowState.workflow.data_status[index].appraisal }
    state.workflowState.appraisalIndex = -1
  },
  OPEN_REASON_DIALOG (state, index) {
    state.reason.dialog = true
    state.reason.index = index
  },
  CLOSE_REASON_DIALOG (state) {
    state.reason.dialog = false
  },
  SET_REASON (state, { reason, text, other }) {
    state.reason.reason = reason
    state.reason.other = other
    state.reason.text = text
    state.reason.action = true
  },
  SET_REASON_ACTION (state, value) {
    state.reason.action = value
  },
  RESET_REASON (state) {
    state.reason.dialog = false
    state.reason.index = -1
    state.reason.action = false
    state.reason.reason = null
    state.reason.other = null
    state.reason.text = null
  },
  SET_DEPARTMENT (state, items) {
    state.department = items
  },
  SET_WORKFLOW_STATE (state, { id, type, response }) {
    state.workflowState.object_id = id
    state.workflowState.type = type
    state.workflowState.workflow = response.workflow
    state.workflowState.obj = response.obj
  },
  SET_STATUS (state, { status, whom }) {
    state.status = { status, whom }
  },
  RESET_COMMENT_STATE (state) {
    state.workflowComment = false
    state.workflowAction = -1
  },
  SET_COMMENT_ALREADY_ADDED (state, action) {
    state.workflowState.workflow.data_status[action].comment = false
  },
  SET_NEW_DATE (state, date) {
    state.dateChange[date.field] = date.value
  },
  SET_COMMENT (state, text) {
    state.commentText = text
  },
  SET_DATE (state) {
    state.dateChange.setDate = true
  },
  RESET_DEADLINE_STATE (state) {
    state.commentText = ''
    state.dateChange.setDate = false
  },
  SET_TIMELINE (state, timeline) {
    if (timeline) {
      state.timeline = timeline
    } else {
      state.timeline = null
    }
  }
}

const actions = {
  GET_DEPARTMENT ({ commit, dispatch, state }, payload) {
    dispatch('_get', { path: '/api/v1/process/department/', payload }, { root: true })
      .then(resp => {
        commit('SET_DEPARTMENT', resp.dep)
      })
  },
  GET_WORKFLOW ({ commit, dispatch, state }, { id, type }) {
    dispatch('_get', { path: `/api/v1/process/workflow/${type}/${id}/` }, { root: true })
      .then(resp => {
        workflowData(id, type, resp, commit)
      })
  },
  GO_PROCESS ({ commit, dispatch, state }, { id, type, payload }) {
    return dispatch('_post', { path: `/api/v1/process/workflow/${type}/${id}/`, payload }, { root: true })
  },
  POST_CHANGE_DATA ({ commit, dispatch, state }, { action, payload }) {
    payload.newStart = state.dateChange.newStart
    payload.newDeadline = state.dateChange.newDeadline
    payload.comment = state.commentText
    return dispatch('_post', { path: `/api/v1/process/workflow/change/${action}/`, payload }, { root: true })
  },
  processWorkflow ({ commit, dispatch, state }, i) {
    const id = state.workflowState.object_id
    const type = state.workflowState.type
    if (state.workflowState.workflow.data_status[i].change_date && !state.dateChange.setDate) {
      state.workflowAction = i
      state.flashMessage = 'Новая дата установлена'
      commit('OPEN_DATE_CHANGE_DIALOG')
      return
    }
    if (state.workflowState.workflow.data_status[i].appraisal && !state.workflowState.appraisalAction) {
      commit('OPEN_APPRAISAL_DIALOG', i)
      return
    }
    if (state.workflowState.workflow.data_status[i].comment) {
      state.workflowComment = true
      state.workflowAction = i
      commit('OVERLAY_OFF', null, { root: true })
      commit('comments/OPEN_COMMENT_DIALOG', null, { root: true })
      return
    }
    if (state.workflowState.workflow.data_status[i].reason && !state.reason.action) {
      state.reason.action = true
      commit('OPEN_REASON_DIALOG', i)
      return
    }

    commit('OVERLAY_ON', null, { root: true })
    const payload = {
      step_id: state.workflowState.workflow.id,
      action_id: state.workflowState.workflow.data_status[i].id,
      next: state.workflowState.workflow.data_status[i].next
    }
    if (state.workflowState.workflow.data_status[i].appraisal && state.workflowState.appraisalAction) {
      payload.appraisal = state.workflowState.appraisal[i]
      commit('SET_APPRAISAL_ACTION', false)
    }
    if (state.dateChange.setDate) {
      payload.newStart = state.dateChange.newStart
      payload.newDeadline = state.dateChange.newDeadline
      payload.comment = state.commentText
    }
    if (state.reason.action) {
      payload.reason_data = { reason: state.reason.reason, text: state.reason.text, other: state.reason.other }
      commit('SET_REASON_ACTION', false)
      commit('RESET_REASON')
    }
    dispatch('bpm/GO_PROCESS', { id, type, payload }, { root: true }).then(resp => {
      this._vm.flashMessage.success({ title: 'Процесс', message: '' })
      dispatch('comments/GET_HISTORIES', { id, type }, { root: true })
      dispatch('comments/GET_COMMENTS', { id, type }, { root: true })
      workflowData(id, type, resp, commit)
    }).catch(error => {
      console.log('Error in bpm store', error.response.data)
      const title = error.response.data.title
      const message = error.response.data.error
      this._vm.flashMessage.error({ title, message })
    }).finally(() => { commit('OVERLAY_OFF', null, { root: true }) })
  }
}

function workflowData (id, type, resp, commit) {
  commit('SET_WORKFLOW_STATE', { id, type, response: resp })
  commit('SET_STATUS', { status: resp.state.status, whom: resp.state.whom })
  commit('SET_TIMELINE', resp.timeline)
  if (type === 'project') {
    commit('project/SET_VISIBLE_BUTTON', { button: 'cp', vis: (resp.obj.cp || 0) > 0 }, { root: true })
    commit('project/SET_VISIBLE_BUTTON', { button: 'plan', vis: resp.obj.plan }, { root: true })
    // TODO вынести в настройки на бэке, таблица bpm_properties
    commit('project/SET_VISIBLE_BUTTON', { button: 'edit', vis: ['draft', 'rework', 'initiation'].indexOf(resp.obj.status) !== -1 }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
