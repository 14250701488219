const state = {
}

const mutations = {
}

const actions = {
  GET_SETTINGS ({ commit, dispatch, state }, group) {
    return dispatch('_get', { path: `/api/v1/settings/group/${group}/` }, { root: true })
  },
  POST_SETTING ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/settings/value/change/', payload }, { root: true })
  },
  GET_COMMON_SETTINGS ({ commit, dispatch, state }, type) {
    return dispatch('_get', { path: `/api/v1/settings/common/${type}/` }, { root: true })
  },
  POST_COMMON_SETTINGS ({ commit, dispatch, state }, { type, payload }) {
    return dispatch('_post', { path: `/api/v1/settings/common/${type}/`, payload }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
