<template>
  <v-dialog
    v-model="show"
    :width="width"
  >
    <v-card>
      <v-card-title v-text="title" />
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          text
          @click="btnCancel"
        >
          Отмена
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="btnSave"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'base-dialog',
  props: {
    width: String,
    show: Boolean,
    title: String,
    btnSave: Function,
    btnCancel: Function
  }
}
</script>

<style scoped>

</style>
