const state = {
  rolesAvailable: [],
  pagination: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['full_name'],
    sortDesc: [false]
  },
  dialogMember: false,
  userSearch: null,
  filters: [],
  filterItems: [
    { text: 'Клиент (Фамилия)', type: 'text', field: 'full_name', val: null },
    { text: 'E-Mail', type: 'text', field: 'email', val: null },
    { text: 'Телефон', type: 'text', field: 'mobile', val: null }
  ]
}

const mutations = {
  SET_ROLES_AVAILABLE (state, items) {
    state.rolesAvailable = items
  },
  SET_PAGINATION (state, value) {
    state.pagination = value
  },
  OPEN_DIALOG_MEMBER (state) {
    state.dialogMember = true
  },
  CLOSE_DIALOG_MEMBER (state) {
    state.dialogMember = false
  },
  SET_FILTERS (state, value) {
    state.filters = value
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
