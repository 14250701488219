const state = {
  commentDialog: false,
  comments: [],
  histories: []
}

const mutations = {
  OPEN_COMMENT_DIALOG (state) {
    state.commentDialog = true
  },
  CLOSE_COMMENT_DIALOG (state) {
    state.commentDialog = false
  },
  SET_COMMENTS (state, items) {
    state.comments = items
  },
  SET_HISTORIES (state, items) {
    state.histories = items
  }
}

const actions = {
  GET_COMMENTS ({ commit, dispatch, state }, { id, type }) {
    dispatch('_get', { path: `/api/v1/${type}/${id}/comments/` }, { root: true })
      .then(response => {
        commit('SET_COMMENTS', response.comments)
      })
  },
  GET_HISTORIES ({ commit, dispatch, state }, { id, type }) {
    dispatch('_get', { path: `/api/v1/common/history/${type}/${id}/` }, { root: true })
      .then(response => {
        commit('SET_HISTORIES', response)
      })
  },
  POST_COMMENT_TASK ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/task/${id}/comment/add/`, payload }, { root: true })
  },
  POST_COMMENT_PROJECT ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/project/${id}/comment/add/`, payload }, { root: true })
  },
  POST_COMMENT_PLAN ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/plan/${id}/comment/add/`, payload }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
