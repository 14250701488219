import Vue from 'vue'

const state = {
  items: {
    status: [],
    // TODO перенести users в общий store
    users: [],
    long_goal: [],
    category: []
  },
  visibleButton: {
    project: true,
    plan: false,
    edit: false,
    cp: false,
    report: false,
    finalReport: false
  },
  filters: {
    status: '',
    priority: null,
    startDate: '',
    endDate: '',
    search: '',
    user: ''
  },
  pagination: {}
}

const mutations = {
  SET_ITEMS (state, items) {
    state.items = items
  },
  SET_VISIBLE_BUTTONS (state, vis) {
    state.visibleButton = vis
  },
  SET_VISIBLE_BUTTON (state, { button, vis }) {
    Vue.set(state.visibleButton, button, vis)
  },
  SET_FILTERS (state, { obj, val }) {
    state.filters[obj] = val
  },
  SET_PAGINATION (state, value) {
    state.pagination = value
  }
}

const actions = {
  async GET_PROJECTS ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/project/', params: { params: params } }, { root: true })
  },
  async GET_PROJECT_VIEW ({ commit, dispatch, state }, id) {
    const resp = await dispatch('_get', { path: `/api/v1/project/${id}/view/` }, { root: true })
    commit('SET_VISIBLE_BUTTON', { button: 'finalReport', vis: (resp.project.project_cp.length > 0) ? resp.project.project_cp[resp.cp - 1].state === 255 : false })
    return resp
  },
  async GET_PROJECT ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/project/${id}/` }, { root: true })
  },
  async GET_ITEMS ({ commit, dispatch, state }) {
    const resp = await dispatch('_get', { path: '/api/v1/project/get-items/' }, { root: true })
    commit('SET_ITEMS', resp)
  },
  async POST_PROJECT ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/project/', payload }, { root: true })
  },
  async PUT_PROJECT ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_put', { path: `/api/v1/project/${id}/`, payload }, { root: true })
  },
  async GET_PLAN ({ commit, dispatch, state }, projectId) {
    return dispatch('_get', { path: `/api/v1/project/${projectId}/plan/` }, { root: true })
  },
  async POST_PLAN ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: `/api/v1/project/${payload.project_id}/plan/`, payload }, { root: true })
  },
  async DELETE_PLAN ({ commit, dispatch, state }, { id, projectId }) {
    return dispatch('_delete', { path: `/api/v1/project/${projectId}/plan/${id}/` }, { root: true })
  },
  async GET_PLAN_ITEM ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/project/plan/item/${id}/` }, { root: true })
  },
  async GET_CONTROL_POINT ({ commit, dispatch, state }, id) {
    const resp = await dispatch('_get', { path: `/api/v1/project/${id}/cp/` }, { root: true })
    commit('SET_VISIBLE_BUTTON', { button: 'cp', vis: true })
    commit('SET_VISIBLE_BUTTON', { button: 'plan', vis: resp.plan })
    return resp
  },
  async POST_CONTROL_POINT ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/project/${id}/cp/`, payload }, { root: true })
  },
  async GET_REPORTS ({ commit, dispatch, state }, projectId) {
    const resp = await dispatch('_get', { path: `/api/v1/project/${projectId}/reports/` }, { root: true })
    commit('SET_VISIBLE_BUTTON', { button: 'cp', vis: (resp.point || 0) > 0 })
    commit('SET_VISIBLE_BUTTON', { button: 'plan', vis: resp.plans !== null })
    return resp
  },
  async POST_REPORTS ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/project/${id}/reports/`, payload }, { root: true })
  },
  GET_FINAL_REPORTS_DATA ({ commit, dispatch, state }, projectId) {
    return dispatch('_get', { path: `/api/v1/project/${projectId}/final_report/` }, { root: true })
  },
  TO_CALENDAR ({ commit, dispatch, state }, projectId) {
    return dispatch('_get', { path: `/api/v1/project/${projectId}/calendar/` }, { root: true })
  },
  AUTOCOMPLETE_PROJECT ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/project/autocomplete/', params: { params: params } }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
