<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field v-bind="$attrs" :value="formatDt()" v-on="on" readonly prepend-icon="mdi-calendar" @click:clear="$emit('click:clear')"/>
    </template>
    <v-date-picker
      v-bind="$attrs"
      v-on="$listeners"
      @input="menu = false"
      first-day-of-week="1"
      locale="ru-ru"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'date-picker',
  data: () => ({
    menu: false
  }),
  methods: {
    formatDt () {
      return this.$attrs.value ? this.$moment(this.$attrs.value).format('DD.MM.YYYY') : ''
    }
  }
}
</script>
