const state = {
  legal_form: [{ id: null, name: '' }],
  members: [{ id: null, full_name: '' }],
  dialog: {
    bank: false,
    time: false,
    member: false
  },
  pagination: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['name'],
    sortDesc: [false]
  },
  filters: [],
  filterItems: [
    { text: 'Название', type: 'autocomplete', field: 'name', val: null, action: 'org/AUTOCOMPLETE_ORG' },
    { text: 'ИНН', type: 'text', field: 'inn', val: null },
    { text: 'Руководитель (фамилия)', type: 'text', field: 'leader', val: null }
  ]
}

const getters = {
  getLegalFormByName: state => name => {
    return state.org.legal_form.find(legal => legal.name === name)
  }

}

const mutations = {
  SET_LEGAL_FORM (state, items) {
    state.legal_form = items
  },
  OPEN_FORM (state, form) {
    state.dialog[form] = true
  },
  CLOSE_FORM (state, form) {
    state.dialog[form] = false
  },
  SET_FILTERS (state, value) {
    state.filters = value
  },
  SET_PAGINATION (state, value) {
    state.pagination = value
  }
}

const actions = {
  GET_LIST ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/reference/org/', params: { params: params } }, { root: true })
  },
  GET_ORG ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/reference/org/${id}/` }, { root: true })
  },
  GET_ORG_VIEW ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/reference/org/${id}/view/` }, { root: true })
  },
  GET_ORG_BY_INN ({ commit, dispatch, state }, val) {
    return dispatch('_get', { path: `/api/v1/reference/get_org_by_inn/${val}/` }, { root: true })
  },
  POST_ORG ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/reference/org/', payload }, { root: true })
  },
  PUT_ORG ({ commit, dispatch, state }, payload) {
    return dispatch('_put', { path: '/api/v1/reference/org/', payload }, { root: true })
  },
  GET_LEGAL_FORM ({ commit, dispatch, state }) {
    dispatch('_get', { path: '/api/v1/reference/legal_form/' }, { root: true }).then(response => {
      commit('SET_LEGAL_FORM', response)
    })
  },
  async GET_CHECK_PARTNER_ORG ({ commit, dispatch, state }, id) {
    return await dispatch('_get', { path: `/api/v1/reference/check_partner_org/${id}/` }, { root: true })
  },
  AUTOCOMPLETE_ORG ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/reference/org/autocomplete/', params: { params: params } }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
