const state = {
  component: 'Empty',
  rightDrawer: false,
  params: {},
  width: 512
}

const mutations = {
  CLOSE_VIEW (state) {
    state.rightDrawer = false
    state.component = 'Empty'
    state.params = {}
  },
  RESET_VIEW (state) {
    if (!state.rightDrawer) {
      state.component = 'Empty'
      state.params = {}
    }
  },
  VIEW_MEMBER (state, id) {
    state.width = 512
    state.component = 'MemberView'
    state.params = { id: id }
    state.rightDrawer = true
  },
  VIEW_PARTNER (state, id) {
    state.width = 720
    state.component = 'PartnerView'
    state.params = { id: id }
    state.rightDrawer = true
  },
  VIEW_FACILITY_FLAT_BY_ITEM (state, item) {
    state.width = 512
    state.component = 'FacilityFlatView'
    state.params = { item: item }
    state.rightDrawer = true
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
