<template>
  <v-card
    class="flex-grow-1 justify-space-between align-center"
    flat
  >
    <v-list class="align-start mb-auto">
      <template v-for="(item, key) in menu">
        <template v-if="item.items">
          <v-list-group :key="key" :prepend-icon="item.icon" no-action v-if="$auth.check(item.access)">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
            </template>
            <template v-for="(i, k) in item.items">
              <v-list-item :key="k" :to="i.to" v-if="$auth.check(i.access)" dense>
                <v-list-item-content>
                  <v-list-item-title v-text="i.title" />
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon v-text="i.icon" />
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
        <template v-else>
          <v-list-item :key="key" :to="item.to" v-if="$auth.check(item.access)">
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
<!--    <v-row v-if="!miniVariant">-->
<!--      <v-col class="d-flex justify-center mt-auto">-->
<!--        <span v-if="breakpointLgXl">Версия: {{ $store.state.versionBase }}<br/>-->
<!--      build: {{ $store.state.versionHash.substring(12) }}</span>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </v-card>
</template>

<script>
export default {
  name: 'TaskNavigation',
  props: {
    miniVariant: Boolean
  },
  data: () => ({
    menu: [
      {
        title: 'Главная',
        to: '/',
        access: '',
        icon: 'mdi-home'
      },
      {
        title: 'Проекты',
        access: '',
        icon: 'mdi-briefcase',
        items: [
          {
            title: 'Список',
            to: '/projects/list',
            access: '',
            icon: 'mdi-table-of-contents'
          },
          {
            title: 'Создать проект',
            to: '/projects/create',
            access: 'manager',
            icon: 'mdi-folder-plus'
          }
        ]
      },
      {
        title: 'Задачи',
        access: '',
        icon: 'mdi-calendar-multiple-check',
        items: [
          {
            title: 'Список',
            to: '/tasks/list',
            access: '',
            icon: 'mdi-table-of-contents'
          },
          {
            title: 'Создать задачу',
            to: '/tasks/create',
            access: 'employee',
            icon: 'mdi-folder-plus'
          },
          {
            title: 'Отчеты',
            to: '/tasks/report',
            access: ['admin', 'director', 'supervisor'],
            icon: 'mdi-table-of-contents'
          }
        ]
      },
      {
        title: 'Заявки',
        access: ['ticket'],
        icon: 'mdi-ticket',
        items: [
          {
            title: 'Список',
            to: '/tickets/list',
            access: ['ticket', 'admin'],
            icon: 'mdi-table-of-contents'
          },
          {
            title: 'Создать заявку',
            to: '/tickets/create',
            access: ['ticket'],
            icon: 'mdi-folder-plus'
          },
          {
            title: 'Отчеты',
            to: '/tickets/report',
            access: ['admin', 'director', 'supervisor', 'service'],
            icon: 'mdi-table-of-contents'
          }
        ]
      },
      {
        title: 'Справочники',
        access: ['ticket'],
        icon: 'mdi-file-document-multiple',
        items: [
          {
            title: 'Юридические лица',
            to: '/reference/org',
            access: ['ticket', 'admin'],
            icon: 'mdi-table-of-contents'
          },
          {
            title: 'Физические лица',
            to: '/reference/members',
            access: ['ticket', 'admin'],
            icon: 'mdi-table-of-contents'
          },
          {
            title: 'Объекты',
            to: '/reference/facility',
            access: ['ticket', 'admin'],
            icon: 'mdi-city-variant'
          },
          {
            title: 'Контрагенты',
            to: '/reference/partners',
            access: ['ticket', 'admin'],
            icon: 'mdi-handshake-outline'
          },
          {
            title: 'Прочие справочники',
            to: '/reference/view',
            access: ['ticket', 'admin'],
            icon: 'mdi-table-of-contents'
          }
        ]
      },
      {
        title: 'HR',
        access: '',
        icon: 'mdi-account-group',
        items: [
          {
            title: 'Список',
            to: '/hr/users',
            access: '',
            icon: 'mdi-account-multiple'
          },
          {
            title: 'Подразделения',
            to: '/hr/dep',
            access: ['hr'],
            icon: ''
          },
          {
            title: 'Производственный календарь',
            to: '/hr/calendar',
            access: '',
            icon: 'mdi-calendar'
          }
        ]
      },
      {
        title: 'Телефонный справочник',
        access: '',
        icon: 'mdi-phone-classic',
        to: '/phone_book'
      },
      {
        title: 'Дебиторка',
        access: ['debit'],
        icon: 'mdi-cash-multiple',
        to: '/debit'
      },
      {
        title: 'Настройки',
        access: ['admin'],
        icon: 'mdi-cog',
        items: [
          {
            title: 'Уведомления',
            to: '/settings/workflow',
            access: ['admin'],
            icon: ''
          },
          {
            title: 'Рассылок',
            to: '/settings/common_email',
            access: ['admin'],
            icon: ''
          },
          {
            title: 'Общие',
            to: '/settings/common',
            access: ['admin'],
            icon: ''
          }
        ]
      },
      {
        title: 'Пользователи',
        access: '',
        icon: 'mdi-account-multiple',
        to: '/users/list'
      }
    ]
  }),
  created () {
  },
  mounted () {
  },
  methods: {
    logout () {
      this.$auth.logout()
    },
    breakpointLgXl () {
      return ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name) !== -1
    }
  }
}
</script>

<style scoped>

</style>
