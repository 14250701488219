import Vue from 'vue'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import Vuetify from 'vuetify/lib'
import en from 'vuetify/es5/locale/en'
import ru from 'vuetify/es5/locale/ru'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'

const vuetify = new Vuetify({
  lang: {
    locales: { en, ru },
    current: 'ru'
  }
})

Vue.use(Vuetify)

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

export default vuetify
