const state = {
  pagination: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['full_name'],
    sortDesc: [false]
  },
  search: null,
  dialogPhoneBook: false
}

const mutations = {
  SET_PAGINATION (state, value) {
    state.pagination = value
  },
  OPEN_DIALOG_PHONE_BOOK (state) {
    state.dialogPhoneBook = true
  },
  CLOSE_DIALOG_PHONE_BOOK (state) {
    state.dialogPhoneBook = false
  }
}

const actions = {
  GET_PHONE_BOOK ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/common/phone_book/', params: { params: params } }, { root: true })
  },
  POST_PHONE_BOOK ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/common/phone_book/', payload }, { root: true })
  },
  DELETE_PHONE_BOOK ({ commit, dispatch, state }, { guid }) {
    return dispatch('_delete', { path: '/api/v1/common/phone_book/', params: { guid: guid } }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
