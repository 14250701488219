const state = {
}

const mutations = {
}

const actions = {
  POST_RESET_PASSWORD ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/auth/reset/', payload }, { root: true })
  },
  POST_REQUEST_RESET_PASSWORD ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/auth/reset-password-request/', payload }, { root: true })
  },
  POST_CHANGE_PASSWORD ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/auth/change-password/', payload }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
