import Vue from 'vue'

import auth from '@websanova/vue-auth/src/index.js'
import authBearer from '@websanova/vue-auth/drivers/auth/bearer.js'
import httpAxios from '@websanova/vue-auth/drivers/http/axios.1.x.js'
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x.js'

Vue.use(auth, {
  auth: authBearer,
  http: httpAxios,
  router: router,
  rolesVal: 'roles',
  fetchData: { url: '/api/v1/auth/user/' },
  loginData: { url: '/api/v1/auth/login/' },
  logoutData: { redirect: '/login' },
  refreshData: { url: '/api/v1/auth/refresh/' },
  authRedirect: { path: '/login' }
})
