import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Index from '../views/Index'
import ResetPassword from '../views/ResetPassword'

Vue.use(VueRouter)

Vue.router = new VueRouter({
  hashbang: false,
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index,
      redirect: 'dashboard',
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard'),
          meta: { auth: true, buttons: { refresh: true } }
        },
        {
          path: 'phone_book',
          name: 'PhoneBook',
          component: () => import(/* webpackChunkName: "Dashboard" */ '../views/PhoneBook'),
          meta: { auth: true, buttons: { back: true, refresh: true, add: true } }
        },
        {
          path: 'debit',
          name: 'Debit',
          component: () => import(/* webpackChunkName: "Dashboard" */ '../views/kv/List'),
          meta: { auth: true }
        }
      ]
    },
    {
      path: '/tasks',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: ':id/view',
          name: 'TaskView',
          component: () => import(/* webpackChunkName: "Task" */ '../views/tasks/View'),
          meta: { auth: true, buttons: { back: true } }
        },
        {
          path: ':id/edit',
          name: 'TaskEdit',
          component: () => import(/* webpackChunkName: "Task" */ '../views/tasks/Edit'),
          meta: { auth: 'employee', buttons: { back: true } }
        },
        {
          path: 'create',
          name: 'TaskCreate',
          component: () => import(/* webpackChunkName: "Task" */ '../views/tasks/Create'),
          meta: { auth: 'employee', buttons: { back: true } }
        },
        {
          path: 'list',
          name: 'TaskList',
          component: () => import(/* webpackChunkName: "Task" */ '../views/tasks/List'),
          meta: { auth: true, buttons: { refresh: true, add: true } }
        },
        {
          path: 'report',
          name: 'TaskReport',
          component: () => import(/* webpackChunkName: "Task" */ '../views/tasks/report/Index'),
          meta: { auth: true }
        }
      ]
    },
    {
      path: '/projects',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: ':id/view',
          name: 'ProjectView',
          component: () => import(/* webpackChunkName: "Project" */ '../views/projects/View'),
          meta: { auth: true }
        },
        {
          path: ':id/final',
          name: 'ProjectFinal',
          component: () => import(/* webpackChunkName: "Project" */ '../views/projects/FinalView'),
          meta: { auth: true }
        },
        {
          path: ':id/plan',
          name: 'ProjectPlan',
          component: () => import(/* webpackChunkName: "Project" */ '../views/projects/Plan'),
          mata: { auth: true }
        },
        {
          path: ':id/edit',
          name: 'ProjectEdit',
          component: () => import(/* webpackChunkName: "Project" */ '../views/projects/Edit'),
          mata: { auth: 'manager' }
        },
        {
          path: ':id/cp',
          name: 'ControlPoint',
          component: () => import(/* webpackChunkName: "Project" */ '../views/projects/ControlPoint'),
          meta: { auth: 'manager' }
        },
        {
          path: ':id/reports',
          name: 'CPReport',
          component: () => import(/* webpackChunkName: "Project" */ '../views/projects/CPReports'),
          meta: { auth: 'manager' }
        },
        {
          path: 'create',
          name: 'ProjectCreate',
          component: () => import(/* webpackChunkName: "Project" */ '../views/projects/Create'),
          meta: { auth: 'manager' }
        },
        {
          path: 'list',
          name: 'ProjectList',
          component: () => import(/* webpackChunkName: "Project" */ '../views/projects/List'),
          meta: { auth: true }
        }
      ]
    },
    {
      path: '/users',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: 'list',
          name: 'UserList',
          component: () => import(/* webpackChunkName: "User" */ '../views/users/List'),
          meta: { auth: true }
        },
        {
          path: ':id/edit',
          name: 'UserEdit',
          component: () => import(/* webpackChunkName: "User" */ '../views/users/Edit'),
          meta: { auth: 'hr', buttons: { back: true } }
        },
        {
          path: 'create',
          name: 'UserCreate',
          component: () => import(/* webpackChunkName: "User" */ '../views/users/Create'),
          meta: { auth: 'hr' }
        },
        {
          path: ':id/view',
          name: 'UserView',
          component: () => import(/* webpackChunkName: "User" */ '../views/users/View'),
          meta: { auth: true }
        },
        {
          path: 'profile',
          name: 'Profile',
          component: () => import(/* webpackChunkName: "User" */ '../views/users/Profile'),
          meta: { auth: true }
        },
        {
          path: 'settings',
          name: 'UserSettings',
          component: () => import(/* webpackChunkName: "User" */ '../views/users/SettingsUser'),
          meta: { auth: true }
        }
      ]
    },
    {
      path: '/tickets',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: ':id/view',
          name: 'TicketView',
          component: () => import(/* webpackChunkName: "Task" */ '../views/tickets/View'),
          meta: { auth: true, buttons: { back: true, refresh: true } }
        },
        {
          path: ':id/edit',
          name: 'TicketEdit',
          component: () => import(/* webpackChunkName: "Task" */ '../views/tasks/Edit'),
          meta: { auth: 'employee' }
        },
        {
          path: 'create',
          name: 'TicketCreate',
          component: () => import(/* webpackChunkName: "Task" */ '../views/tickets/Create'),
          meta: { auth: 'employee', buttons: { back: true } }
        },
        {
          path: 'list',
          name: 'TicketList',
          component: () => import(/* webpackChunkName: "Task" */ '../views/tickets/List'),
          meta: { auth: true, buttons: { add: true, refresh: true } }
        },
        {
          path: 'report',
          name: 'TicketReport',
          component: () => import(/* webpackChunkName: "Task" */ '../views/tickets/report/Index'),
          meta: { auth: true }
        }
      ]
    },
    {
      path: '/reference/org',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: '',
          name: 'OrgList',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/org/List'),
          meta: { auth: true, buttons: { add: true, refresh: true } }
        },
        {
          path: ':id/view',
          name: 'OrgView',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/org/View'),
          meta: { auth: true, buttons: { back: true, refresh: true, edit: true } }
        },
        {
          path: 'create',
          name: 'OrgCreate',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/org/Create'),
          meta: { auth: true, buttons: { back: true } }
        },
        {
          path: ':id/edit',
          name: 'OrgEdit',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/org/Edit'),
          meta: { auth: true, buttons: { back: true } }
        }
      ]
    },
    {
      path: '/reference/facility',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: '',
          name: 'FacilityList',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/facility/List'),
          meta: { auth: true, buttons: { add: true, refresh: true } }
        },
        {
          path: ':id/view',
          name: 'FacilityView',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/facility/View'),
          meta: { auth: true, buttons: { back: true, refresh: true } }
        },
        {
          path: 'create',
          name: 'FacilityCreate',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/facility/Create'),
          meta: { auth: true, buttons: { back: true } }
        },
        {
          path: ':id/edit',
          name: 'FacilityEdit',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/facility/Edit'),
          meta: { auth: true, buttons: { back: true } }
        }
      ]
    },
    {
      path: '/reference',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: 'view',
          name: 'ReferenceView',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/Index'),
          meta: { auth: true, buttons: { back: true } }
        }
      ]
    },
    {
      path: '/reference/partners',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: '',
          name: 'PartnersList',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/partners/List'),
          meta: { auth: true, buttons: { refresh: true, add: true } }
        },
        {
          path: ':id/view',
          name: 'PartnersView',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/partners/View'),
          meta: { auth: true, buttons: { back: true, refresh: true } }
        },
        {
          path: 'create',
          name: 'PartnersCreate',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/partners/Create'),
          meta: { auth: true, buttons: { back: true } }
        },
        {
          path: ':id/edit',
          name: 'PartnersEdit',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/partners/Edit'),
          meta: { auth: true, buttons: { back: true } }
        }
      ]
    },
    {
      path: '/reference/members',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: '',
          name: 'MemberList',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/member/List'),
          meta: { auth: true, buttons: { refresh: true /*, add: true */ } }
        },
        {
          path: ':id/view',
          name: 'MemberView',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/member/View'),
          meta: { auth: true, buttons: { back: true, refresh: true } }
        },
        {
          path: 'create',
          name: 'MemberCreate',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/member/Create'),
          meta: { auth: true, buttons: { back: true } }
        },
        {
          path: ':id/edit',
          name: 'MemberEdit',
          component: () => import(/* webpackChunkName: "Reference" */ '../views/reference/member/Edit'),
          meta: { auth: true, buttons: { back: true } }
        }
      ]
    },
    {
      path: '/hr',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: 'users',
          name: 'HrUsers',
          component: () => import(/* webpackChunkName: "Hr" */ '../views/hr/List'),
          meta: { auth: true }
        },
        {
          path: 'dep',
          name: 'HrDep',
          component: () => import(/* webpackChunkName: "Hr" */ '../views/hr/Department'),
          meta: { auth: 'hr' }
        },
        {
          path: 'calendar',
          name: 'ProductionCalendar',
          component: () => import(/* webpackChunkName: "Hr" */ '../views/hr/ProductionCalendar'),
          meta: { auth: true }
        },
        {
          path: 'time_sheet/:id',
          name: 'TimeSheet',
          component: () => import(/* webpackChunkName: "Hr" */ '../views/hr/TimeSheet'),
          meta: { auth: true, buttons: { back: true } }
        }
      ]
    },
    {
      path: '/settings',
      component: Index,
      meta: { auth: true },
      children: [
        {
          path: 'workflow',
          name: 'SettingsWorkflow',
          component: () => import(/* webpackChunkName: "Settings" */ '../views/settings/SettingsWorkFlow'),
          meta: { auth: 'admin' }
        },
        {
          path: 'common_email',
          name: 'SettingsCommonEmail',
          component: () => import(/* webpackChunkName: "Settings" */ '../views/settings/SettingsCommonEmail'),
          meta: { auth: 'admin' }
        },
        {
          path: 'common',
          name: 'SettingsCommon',
          component: () => import(/* webpackChunkName: "Settings" */ '../views/settings/SettingsCommon'),
          meta: { auth: 'admin', buttons: { back: true } }
        }
      ]
    },
    // {
    //   path: '/uploads',
    //   name: 'Uploads',
    //   component: () => import(/* webpackChunkName: "uploads" */ '../views/upload'),
    //   meta: { auth: true }
    // },
    // {
    //   path: '/download',
    //   name: 'Download',
    //   component: () => import(/* webpackChunkName: "download" */ '../views/download'),
    //   meta: { auth: true }
    // },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { auth: false }
    },
    {
      path: '/recovery/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: { auth: false }
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
      path: '*',
      component: Index,
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('../views/Error')
        }
      ]
    }

  ]
})

export default Vue.router
