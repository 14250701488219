const state = {
  items: {
    places: []
  },
  pagination: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['created_dt'],
    sortDesc: [true]
  },
  filters: [],
  filterItems: [
    { text: 'Объект', type: 'select', field: 'places', select: [], val: null, col: 6, itemText: 'place', itemValue: 'place' },
    { text: 'Дата', type: 'date', field: 'dt', val: null, col: 6 }
  ]
}

const mutations = {
  SET_ITEMS (state, items) {
    state.items = items
    const item = state.filterItems.find(({ field }) => field === 'places')
    item.select = state.items.places
  },
  SET_FILTERS (state, value) {
    state.filters = value
  },
  SET_PAGINATION (state, value) {
    state.pagination = value
  }
}

const actions = {
  async GET_ITEMS ({ commit, dispatch, state }) {
    const resp = await dispatch('_get', { path: '/api/v1/kv/get-items/' }, { root: true })
    commit('SET_ITEMS', resp)
  },
  GET_DEBIT ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/kv/', params: { params: params } }, { root: true })
  },
  POST_DEBIT_COMMENT ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/kv/comment/', payload }, { root: true })
  },
  GET_DEBIT_HISTORY ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/kv/debit/', params: { params: params } }, { root: true })
  },
  POST_UPLOAD_FILE ({ commit, dispatch, state }, { id, formData }) {
    return dispatch('_post', {
      path: '/api/v1/kv/upload/',
      payload: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
