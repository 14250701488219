const state = {
  items: {
    users: [],
    project: [],
    status: [],
    priority: [],
    category: [],
    process: [],
    department: [],
    default_process: null
  },
  project_task: [],
  commentDialog: false,
  copyTaskId: null,
  subTask: false,
  filters: [],
  filterItems: [
    { text: 'Показать закрытые', type: 'item', field: 'closed', val: false },
    { text: 'Просроченные', type: 'item', field: 'expired', val: false },
    { text: 'Номер задачи', type: 'text', field: 'full_number', val: null, col: 6 },
    { text: 'Исполнитель', type: 'combobox', field: 'executor', select: [], val: null, visible: ['director', 'manager'], col: 6 },
    { text: 'Заказчик', type: 'combobox', field: 'customer', select: [], val: null, col: 6 },
    { text: 'Подразделение', type: 'select', field: 'dep', select: [], val: null, visible: 'director', col: 6 },
    {
      text: 'Приоритет',
      type: 'select',
      field: 'priority',
      select: [
        { id: 10, name: 'Высокий', color: 'red' },
        { id: 20, name: 'Повышенный', color: 'orange' },
        { id: 30, name: 'Нормальный', color: 'green' }
      ],
      val: null,
      col: 6
    },
    {
      text: 'Категория задачи',
      type: 'select',
      field: 'category',
      select: [
        { id: 'task', name: 'Задача' },
        { id: 'task_owner', name: 'Задача от собственника' },
        { id: 'task_corr', name: 'Корректирующая задача' }
      ],
      val: null,
      col: 6
    },
    { text: 'Статус', type: 'select', field: 'status', select: [], val: null, col: 6 },
    { text: 'Проект', type: 'autocomplete', field: 'project', val: null, action: 'project/AUTOCOMPLETE_PROJECT', col: 6 },
    { text: 'Теги', type: 'select', field: 'tags', select: [], val: null, col: 6, multi: true },
    { type: 'empty', col: 6 },
    { text: 'Начальная дата', type: 'date', field: 'deadline_dt_ge', val: null, col: 6 },
    { text: 'Конечная дата', type: 'date', field: 'deadline_dt_le', val: null, col: 6 }
  ],
  pagination: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['full_number'],
    sortDesc: [false]
  },
  changeExecutorDialog: false,
  changeCustomerDialog: false,
  executorList: [],
  isFromPlan: false,
  fromPlan: {
    name: '',
    start_dt: undefined,
    end_dt: undefined,
    project_id: undefined,
    project_task_id: undefined
  }
}

const mutations = {
  SET_ITEMS (state, items) {
    state.items = items
    let item = state.filterItems.find(({ field }) => field === 'dep')
    item.select = state.items.department
    let obj = []
    state.items.status.forEach(function (item_) {
      obj.push({ id: item_.code, name: item_.name })
    })
    item = state.filterItems.find(({ field }) => field === 'status')
    item.select = obj
    obj = []
    state.items.users.forEach(function (item_) {
      obj.push({ id: item_.id, name: item_.full_name })
    })
    item = state.filterItems.find(({ field }) => field === 'executor')
    item.select = obj
    item = state.filterItems.find(({ field }) => field === 'customer')
    item.select = obj
    item = state.filterItems.find(({ field }) => field === 'tags')
    item.select = state.items.tags
  },
  SET_PROJECT_TASK (state, items) {
    state.project_task = items
  },
  SET_COPY_TASK_ID (state, id) {
    state.copyTaskId = id
  },
  CLEAR_COPY_TASK (state) {
    state.copyTaskId = null
  },
  SET_SUBTASK_ID (state, id) {
    state.copyTaskId = id
    state.subTask = true
  },
  CLEAR_SUBTASK (state) {
    state.copyTaskId = null
    state.subTask = false
  },

  SET_FILTERS (state, value) {
    state.filters = value
  },
  SET_PAGINATION (state, value) {
    state.pagination = value
  },

  DIALOG_CHANGE_CUSTOMER_OPEN (state) {
    state.changeCustomerDialog = true
  },
  DIALOG_CHANGE_CUSTOMER_CLOSE (state) {
    state.changeCustomerDialog = false
  },

  DIALOG_CHANGE_EXECUTOR_OPEN (state) {
    state.changeExecutorDialog = true
  },
  DIALOG_CHANGE_EXECUTOR_CLOSE (state) {
    state.changeExecutorDialog = false
  },
  SET_EXECUTOR_LIST (state, list) {
    state.executorList = list
  },
  SET_FROM_PLAN (state, item) {
    state.fromPlan.name = item.name
    state.fromPlan.start_dt = item.start_dt
    state.fromPlan.end_dt = item.end_dt
    state.fromPlan.project_id = item.project_id
    state.fromPlan.project_task_id = item.id
    state.isFromPlan = true
  },
  CLEAR_AFTER_CREATE_FROM_PLAN (state) {
    state.fromPlan.name = ''
    state.fromPlan.start_dt = undefined
    state.fromPlan.end_dt = undefined
    state.fromPlan.project_id = undefined
    state.fromPlan.project_task_id = undefined
    state.isFromPlan = false
  }
}

const actions = {
  GET_TASKS ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/task/', params: { params: params } }, { root: true })
  },
  GET_SUBTASKS ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/task/${id}/sub-task/` }, { root: true })
  },
  async GET_TASK_VIEW ({ commit, dispatch, state }, id) {
    const resp = await dispatch('_get', { path: `/api/v1/task/${id}/view/` }, { root: true })
    return resp
  },
  async GET_TASK ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/task/${id}/` }, { root: true })
  },
  async GET_ITEMS ({ commit, dispatch, state }) {
    const resp = await dispatch('_get', { path: '/api/v1/task/get-items/' }, { root: true })
    commit('SET_ITEMS', resp)
  },
  async POST_TASK ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/task/', payload }, { root: true })
  },
  async PUT_TASK ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_put', { path: `/api/v1/task/${id}/`, payload }, { root: true })
  },
  async PROCESS_TASK ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/task/${id}/process/task/`, payload }, { root: true })
  },
  async GET_PROJECT_TASKS ({ commit, dispatch, state }, id) {
    const resp = await dispatch('_get', { path: `/api/v1/task/project_tasks/${id}/` }, { root: true })
    commit('SET_PROJECT_TASK', resp.tasks)
  },
  POST_WATCHER_ADD ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/task/${id}/watcher/add/`, payload }, { root: true })
  },
  POST_WATCHER_REMOVE ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/task/${id}/watcher/remove/`, payload }, { root: true })
  },
  POST_CHECK_TASK_COUNT ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/task/check/count/', payload }, { root: true })
  },
  POST_CHANGE_EXECUTOR ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/task/${id}/executor/change/`, payload }, { root: true })
  },
  POST_CHANGE_CUSTOMER ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/task/${id}/customer/change/`, payload }, { root: true })
  },
  DIALOG_CHANGE_EXECUTOR ({ commit, dispatch, state }, executorList) {
    if (executorList.length > 0) {
      commit('SET_EXECUTOR_LIST', executorList)
      commit('DIALOG_CHANGE_EXECUTOR_OPEN')
    } else {
      dispatch('user/GET_RECURSIVE', {}, { root: true }).then(response => {
        commit('SET_EXECUTOR_LIST', response)
      }).finally(() => {
        commit('DIALOG_CHANGE_EXECUTOR_OPEN')
      })
    }
  },
  DIALOG_CHANGE_CUSTOMER ({ commit, dispatch, state }, executorList) {
    if (executorList.length > 0) {
      commit('SET_EXECUTOR_LIST', executorList)
      commit('DIALOG_CHANGE_CUSTOMER_OPEN')
    } else {
      dispatch('user/GET_RECURSIVE', {}, { root: true }).then(response => {
        commit('SET_EXECUTOR_LIST', response)
      }).finally(() => {
        commit('DIALOG_CHANGE_CUSTOMER_OPEN')
      })
    }
  },
  GET_REPORT_LIST ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/task/task_report/' }, { root: true })
  },
  POST_REPORT ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/task/task_report/', payload }, { root: true })
  },
  DELETE_REPORT ({ commit, dispatch, state }, { filename }) {
    return dispatch('_delete', { path: `/api/v1/task/task_report/${filename}` }, { root: true })
  },
  async GET_DOWNLOAD_REPORT ({ commit, dispatch, state }, { filename }) {
    return dispatch('_get', {
      path: `/api/v1/task/task_report/${filename}`,
      params: { responseType: 'blob' }
    }, { root: true })
  },
  POST_CHANGE_LABOR_COSTS ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/task/labor_costs/change/', payload }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
