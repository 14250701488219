
const state = {
  dialog: false,
  orgItem: undefined,
  memberItem: undefined,
  isAutoCreate: false,
  autoCreate: undefined,
  filters: [],
  filterItems: [
    { text: 'Название', type: 'autocomplete', field: 'name', val: null, action: 'partners/AUTOCOMPLETE_PARTNERS', additional: 'name_additional' },
    { text: 'Тип', type: 'select', field: 'type_partners', select: [], val: null },
    { text: 'Объект недвижимости', type: 'select', field: 'facility', select: [], val: null },
    {
      text: 'Форма собственности',
      type: 'select',
      field: 'type',
      select: [
        { id: 'entity', name: 'Юридическое лицо' },
        { id: 'individual', name: 'Физичесое лицо' }
      ],
      val: null
    }
  ],
  pagination: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['name'],
    sortDesc: [false]
  }
}

const mutations = {
  SET_ITEMS (state, items) {
    let item = {}
    item = state.filterItems.find(({ field }) => field === 'type_partners')
    item.select = items.type_partners

    item = state.filterItems.find(({ field }) => field === 'facility')
    item.select = items.facility
  },
  OPEN_DIALOG (state) {
    state.dialog = true
  },
  CLOSE_DIALOG (state) {
    state.dialog = false
  },
  CREATE_ORG (state, item) {
    state.autoCreate = {
      type: 'entity',
      name: item.name,
      org_id: item.id
    }
    state.isAutoCreate = true
  },
  CREATE_MEMBER (state, item) {
    state.autoCreate = {
      type: 'individual',
      name: item.full_name,
      user_id: item.id,
      contact_person: [item.id]
    }
    state.isAutoCreate = true
  },
  RESET_AUTO_CREATE (state) {
    state.isAutoCreate = false
    state.autoCreate = undefined
  },
  SET_FILTERS (state, value) {
    state.filters = value
  },
  SET_PAGINATION (state, value) {
    state.pagination = value
  }
}

const actions = {
  async GET_ITEMS ({ commit, dispatch, state }) {
    const resp = await dispatch('_get', { path: '/api/v1/reference/partner/get-items/' }, { root: true })
    commit('SET_ITEMS', resp)
    return resp
  },
  GET_PARTNERS ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/reference/partners/', params: { params: params } }, { root: true })
  },
  GET_PARTNER ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/reference/partner/${id}/` }, { root: true })
  },
  GET_PARTNER_FORM ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/reference/partner/${id}/form/` }, { root: true })
  },
  GET_ORG_LIST ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/reference/org/list/' }, { root: true })
  },
  POST_PARTNER ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/reference/partner/', payload }, { root: true })
  },
  PUT_PARTNER ({ commit, dispatch, state }, payload) {
    return dispatch('_put', { path: '/api/v1/reference/partner/', payload }, { root: true })
  },
  AUTOCOMPLETE_PARTNERS ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/reference/partner/autocomplete/', params: { params: params } }, { root: true })
  },
  POST_CONTRACT ({ commit, dispatch, state }, { id, formData }) {
    return dispatch('_post', {
      path: `/api/v1/reference/partner/${id}/contract/`,
      payload: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }, { root: true })
  },
  GET_CONTRACT_FILE ({ commit, dispatch, state }, { id, guid }) {
    return dispatch('_get', {
      path: `/api/v1/reference/partner/${id}/contract/`,
      params: { responseType: 'blob', params: { guid: guid } }
    }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
