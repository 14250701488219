<template>
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        size="36"
        v-bind="attrs"
        v-on="on"
        class="ml-2"
      >
        <v-img
          :src="($auth.user().avatar === null) ? '/static/default_avatar.png' : `/api/v1/uploads/avatar/${$auth.user().avatar}`"
        />
      </v-avatar>
    </template>
    <v-card>
      <v-card-title class="text-subtitle-2">Меню</v-card-title>
      <v-divider></v-divider>
      <v-list dense class="py-0">
        <v-list-item to="/users/profile" dense>
          <v-list-item-icon class="mr-3"><v-icon>mdi-account-box-outline</v-icon></v-list-item-icon>
          <v-list-item-content class="text-body-2 py-0">Профиль</v-list-item-content>
        </v-list-item>
        <v-list-item to="/users/settings" dense>
          <v-list-item-icon class="mr-3"><v-icon>mdi-cog</v-icon></v-list-item-icon>
          <v-list-item-content class="text-body-2 py-0">Настройки</v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout" dense>
          <v-list-item-icon class="mr-3"><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-content class="text-body-2 py-0">Выход</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'UserMenu',
  methods: {
    logout () {
      this.$auth.logout()
    }
  }
}
</script>

<style scoped>

</style>
