const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  GET_CITY ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/fias/addrobj/', params: { params: params } }, { root: true })
  },
  GET_HOUSE ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/fias/house/', params: { params: params } }, { root: true })
  },
  GET_FLAT_COUNT ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/fias/flats/', params: { params: params } }, { root: true })
  },
  GET_FACILITY ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/reference/facility/' }, { root: true })
  },
  GET_FACILITY_VIEW ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/reference/facility/${id}/view/` }, { root: true })
  },
  GET_FACILITY_FLAT ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/reference/facility/${id}/flat/` }, { root: true })
  },
  POST_FACILITY_FLAT ({ commit, dispatch, state }, { id, payload }) {
    // Добавление помещения к объекту
    return dispatch('_post', { path: `/api/v1/reference/facility/${id}/flat/`, payload }, { root: true })
  },
  DELETE_FACILITY_FLAT ({ commit, dispatch, state }, { id, params }) {
    return dispatch('_delete', { path: `/api/v1/reference/facility/${id}/flat/`, params: { id: params } }, { root: true })
  },
  POST_FACILITY_FLOOR ({ commit, dispatch, state }, { id, formData }) {
    // Добавление этажа к объекту
    return dispatch('_post', {
      path: `/api/v1/reference/facility/${id}/floor/`,
      payload: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }, { root: true })
  },
  DELETE_FACILITY_FLOOR ({ commit, dispatch, state }, { id, params }) {
    return dispatch('_delete', { path: `/api/v1/reference/facility/${id}/floor/`, params: { id: params } }, { root: true })
  },
  POST_FACILITY ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/reference/facility/', payload }, { root: true })
  },
  POST_FLOOR_FOR_FLAT ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/reference/facility/flat/floor/', payload }, { root: true })
  },
  POST_FLOOR_TYPE_CHANGE ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/reference/facility/floor/type/', payload }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
