const state = {
  items: {
    users: [],
    members: [],
    partners: [],
    channel: [],
    group: [],
    declarer: [],
    facilities: [],
    type: [],
    priority: [],
    category: [],
    process: [],
    executor: [],
    department: [],
    default_process: null,
    worker: []
  },
  pagination: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['created_dt'],
    sortDesc: [true]
  },
  changeExecutorDialog: false,
  filters: [],
  filterItems: [
    { text: 'Показать закрытые', type: 'item', field: 'closed', val: false },
    { text: 'Номер заявки', type: 'text', field: 'full_number', val: null, col: 6 },
    { text: 'Руководитель', type: 'combobox', field: 'executor', select: [], val: null, col: 6/* , visible: ['director', 'manager'] */ },
    { text: 'Контрагент', type: 'combobox', field: 'partner', select: [], val: null, col: 6 },
    {
      text: 'Приоритет',
      type: 'select',
      field: 'priority',
      select: [
        { id: 5, name: 'Авария', color: 'red' },
        { id: 10, name: 'Высокий', color: 'orange' },
        { id: 20, name: 'Обычный', color: 'green' },
        { id: 30, name: 'Низкий', color: 'green' }
      ],
      val: null,
      col: 6
    },
    { text: 'Тип заявки', type: 'select', field: 'type', select: [], val: null, col: 6 },
    { text: 'Статус', type: 'select', field: 'status', select: [], val: null, col: 6 },
    { text: 'Объект', type: 'select', field: 'facility', select: [], multi: true, val: null, col: 6 },
    { text: 'Исполнитель', type: 'combo_multisearch', field: 'worker', select: [], additional: 'position', multi: true, val: null, col: 6 },
    { text: 'Начальная дата регистрации', type: 'date', field: 'created_dt_ge', val: null, col: 6 },
    { text: 'Конечная дата регистрации', type: 'date', field: 'created_dt_le', val: null, col: 6 }
  ]
}

const mutations = {
  SET_ITEMS (state, items) {
    let item
    let obj = []
    state.items = items
    state.items.status.forEach(function (item, idx, array) {
      obj.push({ id: item.code, name: item.name })
    })
    item = state.filterItems.find(({ field }) => field === 'status')
    item.select = obj
    obj = []

    state.items.executor.forEach(function (item, idx, array) {
      obj.push({ id: item.id, name: item.full_name })
    })
    item = state.filterItems.find(({ field }) => field === 'executor')
    item.select = obj

    item = state.filterItems.find(({ field }) => field === 'partner')
    item.select = state.items.partners

    item = state.filterItems.find(({ field }) => field === 'facility')
    item.select = state.items.facilities

    item = state.filterItems.find(({ field }) => field === 'type')
    item.select = state.items.type

    item = state.filterItems.find(({ field }) => field === 'worker')
    item.select = state.items.worker
  },
  SET_PAGINATION (state, value) {
    state.pagination = value
  },
  DIALOG_CHANGE_EXECUTOR_OPEN (state) {
    state.changeExecutorDialog = true
  },
  DIALOG_CHANGE_EXECUTOR_CLOSE (state) {
    state.changeExecutorDialog = false
  },
  SET_FILTERS (state, value) {
    state.filters = value
  }
}

const actions = {
  GET_TICKETS ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/ticket/', params: { params: params } }, { root: true })
  },
  async GET_TICKET_VIEW ({ commit, dispatch, state }, id) {
    const resp = await dispatch('_get', { path: `/api/v1/ticket/${id}/view/` }, { root: true })
    return resp
  },
  async GET_TICKET ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/ticket/${id}/` }, { root: true })
  },
  async GET_ITEMS ({ commit, dispatch, state }) {
    const resp = await dispatch('_get', { path: '/api/v1/ticket/get-items/' }, { root: true })
    commit('SET_ITEMS', resp)
    return resp
  },
  async POST_TICKET ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/ticket/', payload }, { root: true })
  },
  GET_OBJECT ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/ticket/object/', params: { params: params } }, { root: true })
  },
  async PUT_TICKET ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_put', { path: `/api/v1/ticket/${id}/`, payload }, { root: true })
  },
  async PROCESS_TICKET ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/ticket/${id}/process/task/`, payload }, { root: true })
  },
  POST_CHANGE_EXECUTOR ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/ticket/${id}/executor/change/`, payload }, { root: true })
  },
  POST_WORKER ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/ticket/${id}/worker/add/`, payload }, { root: true })
  },
  POST_CHANGE_WORKER ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_post', { path: `/api/v1/ticket/${id}/worker/change/`, payload }, { root: true })
  },
  POST_WORK ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/ticket/work/', payload }, { root: true })
  },
  POST_MATERIAL ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/ticket/material/', payload }, { root: true })
  },
  POST_TIME ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/ticket/time/', payload }, { root: true })
  },
  POST_CHANGE_PRIORITY ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/ticket/priority/change/', payload }, { root: true })
  },

  GET_REPORT_LIST ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/ticket/ticket_report/' }, { root: true })
  },
  POST_REPORT ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/ticket/ticket_report/', payload }, { root: true })
  },
  DELETE_REPORT ({ commit, dispatch, state }, { filename }) {
    return dispatch('_delete', { path: `/api/v1/ticket/ticket_report/${filename}` }, { root: true })
  },
  async GET_DOWNLOAD_REPORT ({ commit, dispatch, state }, { filename }) {
    return dispatch('_get', {
      path: `/api/v1/ticket/ticket_report/${filename}`,
      params: { responseType: 'blob' }
    }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
