const state = {
  rolesAvailable: [],
  pagination: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['full_name'],
    sortDesc: [false]
  },
  dialogMember: false,
  userSearch: null,
  filters: [],
  filterItems: [
    { text: 'Клиент (Фамилия)', type: 'text', field: 'full_name', val: null },
    { text: 'E-Mail', type: 'text', field: 'email', val: null },
    { text: 'Телефон', type: 'text', field: 'mobile', val: null }
  ]
}

const mutations = {
  SET_ROLES_AVAILABLE (state, items) {
    state.rolesAvailable = items
  },
  SET_PAGINATION (state, value) {
    state.pagination = value
  },
  OPEN_DIALOG_MEMBER (state) {
    state.dialogMember = true
  },
  CLOSE_DIALOG_MEMBER (state) {
    state.dialogMember = false
  },
  SET_FILTERS (state, value) {
    state.filters = value
  }
}

const actions = {
  GET_USERS ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/users/' }, { root: true })
  },
  GET_USER_OWN ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/users/user/' }, { root: true })
  },
  GET_USER ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/users/user/${id}/` }, { root: true })
  },
  POST_USER ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/users/user/', payload }, { root: true })
  },
  PUT_USER ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_put', { path: `/api/v1/users/user/${id}/`, payload }, { root: true })
  },
  PUT_OWN ({ commit, dispatch, state }, payload) {
    return dispatch('_put', { path: '/api/v1/users/user/', payload }, { root: true })
  },
  POST_UPLOAD_AVATAR ({ commit, dispatch, state }, { id, formData }) {
    return dispatch('_post', {
      path: `/api/v1/uploads/avatar/${id}/`,
      payload: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }, { root: true })
  },
  GET_ROLES_TREE ({ commit, dispatch, state }) {
    dispatch('_get', { path: '/api/v1/users/user/roles/' }, { root: true })
      .then(resp => {
        commit('SET_ROLES_AVAILABLE', resp.roles)
      })
  },
  GET_RECURSIVE ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/users/recursive/' }, { root: true })
  },
  GET_MEMBERS ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/users/members/' }, { root: true })
  },
  GET_MEMBER_FORM ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/users/member/${id}/form/` }, { root: true })
  },
  POST_MEMBER ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/users/member/', payload }, { root: true })
  },
  GET_MEMBERS_ONLY ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/users/members/only/', params: { params: params } }, { root: true })
  },
  async GET_CHECK_PARTNER_MEMBER ({ commit, dispatch, state }, id) {
    return await dispatch('_get', { path: `/api/v1/reference/check_partner_member/${id}/` }, { root: true })
  },
  GET_USER_SETTINGS ({ commit, dispatch, state }, group) {
    return dispatch('_get', { path: `/api/v1/settings/user/${group}/` }, { root: true })
  },
  POST_USER_SETTING ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/settings/user/change/', payload }, { root: true })
  },
  AUTOCOMPLETE_MEMBERS ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/users/user/autocomplete/', params: { params: params } }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
