const state = {
  rolesAvailable: [],
  dialogMember: false,
  userSearch: null
}

const mutations = {
  SET_PAGINATION (state, value) {
    state.pagination = value
  }
}

const actions = {
  GET_USERS ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/hr/users/' }, { root: true })
  },
  GET_DEPARTMENT ({ commit, dispatch, state }, payload) {
    return dispatch('_get', { path: '/api/v1/users/hr/dep/' }, { root: true })
  },
  PUT_LEADER ({ commit, dispatch, state }, { id, payload }) {
    return dispatch('_put', { path: `/api/v1/users/hr/dep/${id}/`, payload }, { root: true })
  },
  GET_DEPARTMENT_USERS ({ commit, dispatch, state }, id) {
    return dispatch('_get', { path: `/api/v1/users/hr/dep/${id}/users/` }, { root: true })
  },
  PUT_DEPARTMENT_PARAMS ({ commit, dispatch, state }, payload) {
    return dispatch('_put', { path: '/api/v1/users/hr/dep/params/', payload }, { root: true })
  },
  GET_CALENDAR ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/hr/month/', params: { params: params } }, { root: true })
  },
  GET_UPDATE_CALENDAR ({ commit, dispatch, state }, params = {}) {
    return dispatch('_get', { path: '/api/v1/hr/update/', params: { params: params } }, { root: true })
  },
  POST_EVENT_DATA ({ commit, dispatch, state }, payload) {
    return dispatch('_post', { path: '/api/v1/hr/events/', payload }, { root: true })
  },
  DELETE_EVENT_DATA ({ commit, dispatch, state }, payload) {
    return dispatch('_delete', { path: '/api/v1/hr/events/', params: payload }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
