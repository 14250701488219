const actions = {
  async GET_DASHBOARD ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/dashboard/' }, { root: true })
  }
}

export default {
  namespaced: true,
  actions
}
