const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  GET_REFERENCE ({ commit, dispatch, state }, type) {
    return dispatch('_get', { path: `/api/v1/reference/view/${type}/` }, { root: true })
  },
  GET_GROUPS ({ commit, dispatch, state }) {
    return dispatch('_get', { path: '/api/v1/reference/view/groups/' }, { root: true })
  },
  POST_REFERENCE ({ commit, dispatch, state }, { type, payload }) {
    return dispatch('_post', { path: `/api/v1/reference/view/${type}/`, payload }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
